import React, { Suspense, lazy } from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import { Toaster } from "react-hot-toast";
import { BrowserRouter } from "react-router-dom";
import rootReducer from "./reducer";
import { configureStore } from "@reduxjs/toolkit";
import { HelmetProvider } from "react-helmet-async";
const HeavyComponent = lazy(() => import("./App.js"));

const store = configureStore({
  reducer: rootReducer,
});

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <BrowserRouter>
        <Suspense
          fallback={
            <div className="flex items-center justify-center fixed inset-0 bg-gradient-to-r from-gray-300 via-gray-400 to-gray-500 z-50">
              <div className="text-center">
                <div className="loader ease-linear rounded-full border-8 border-t-8 border-white h-32 w-32 mb-4"></div>
                <h2 className="text-4xl font-semibold text-white">
                  Loading...
                </h2>
              </div>
            </div>
          }
        >
          <HelmetProvider>
            <HeavyComponent />
          </HelmetProvider>
        </Suspense>
        <Toaster position="top-center" />
      </BrowserRouter>
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
