import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: false,
  projects: localStorage.getItem("projects")
    ? JSON.parse(localStorage.getItem("projects"))
    : [],
};

const projectSlice = createSlice({
    name:"project",
    initialState : initialState,
    reducers:{
        setLoading(state, value){
             state.loading = value.payload;
        },
        setProjects(state, value){
            state.projects = value.payload
        }
    }
})


export const {setLoading, setProjects} = projectSlice.actions;

export default projectSlice.reducer
