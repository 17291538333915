import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: false,
  user: localStorage.getItem("user")
    ? JSON.parse(localStorage.getItem("user"))
    : null,
  users: localStorage.getItem("users")
    ? JSON.parse(localStorage.getItem("users"))
    : [],
};

const userSlice = createSlice({
    name:"user",
    initialState:initialState,
    reducers:{
        setLoading(state, value){
           state.loading = value.payload
        },
        setUser(state, value){
            state.user = value.payload
        },
        setUsers(state, value){
            state.users = value.payload
        }
    }
})

export const {setLoading, setUser, setUsers} = userSlice.actions;

export default userSlice.reducer;
