import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: false,
  review: [],
};

const reviewSlice = createSlice({
  name: "review",
  initialState: initialState,
  reducers: {
    setLoading(state, value) {
      state.loading = value.payload;
    },
    setReview(state, value) {
      state.review = value.payload;
    },
  },
});

export const { setLoading, setReview } = reviewSlice.actions;
export default reviewSlice.reducer;
