import { combineReducers } from "@reduxjs/toolkit";
import AuthSlice from "../slices/AuthSlice";
import ReviewSlice from "../slices/ReviewSlice";
import UserSlice from "../slices/UserSlice";
import QuerySlice from "../slices/QuerySlice";
import ProjectSlice from "../slices/Project";
import AdminSlice from "../slices/Admin"

const rootReducer = combineReducers({
  auth: AuthSlice,
  admin:AdminSlice,
  review: ReviewSlice,
  user: UserSlice,
  query: QuerySlice,
  project: ProjectSlice,
});

export default rootReducer;
