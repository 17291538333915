import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  users: [],
  loading: false,
};

const adminSlice = createSlice({
  name: "admin",
  initialState: initialState,
  reducers: {
    setLoading(state, value) {
      state.loading = value.payload;
    },
    setUsers(state, value) {
      state.users = value.payload;
    },
  },
});

export const { setLoading, setUsers } = adminSlice.actions;

export default adminSlice.reducer;
