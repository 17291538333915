import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: false,
  query: localStorage.getItem("query")
    ? JSON.parse(localStorage.getItem("query"))
    : null,
    
  queries: localStorage.getItem("queries")
    ? JSON.parse(localStorage.getItem("queries"))
    : [],

};


const querySlice = createSlice({
    name : "query",
    initialState : initialState,
    reducers : {
        setLoading(state, value){
            state.loading = value.payload
        },
        setQuery(state, value){
            state.query = value.payload
        },
        setQueries(state, value){
            state.queries = value.payload
        }
    }
})

export const {setLoading, setQueries, setQuery} = querySlice.actions;
export default querySlice.reducer;
